body {
  margin: 0;
  font-family: Estedad, tahoma, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666;
}

* {
  font-family: Estedad;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

select {
  border-radius: 999px;
  font-size: 18px;
  padding: 12px 20px;
  min-width: 100px;
  cursor: pointer;
}

button {
  border-radius: 999px;
  border: none;
  background-color: rgba(26, 36, 33, 1);
  color: #fff;
  font-size: 18px;
  padding: 12px 20px;
  min-width: 100px;
  cursor: pointer;
}

button.primary {
  background-color: #5857ff;
}

button:hover {
  opacity: 0.9;
}

button:disabled {
  opacity: 0.5;
}

textarea {
  width: 100%;
  box-sizing: border-box;
  border-radius: 16px;
  font-size: 18px;
  padding: 12px 20px;
  resize: none;
  border: none;
}
