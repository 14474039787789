/* Estedad */
@font-face {
  font-family: Estedad;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../fonts/Estedad-FD/Estedad-FD-ExtraLight.woff2") format("woff2");
}
@font-face {
  font-family: Estedad;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../fonts/Estedad-FD/Estedad-FD-Light.woff2") format("woff2");
}
@font-face {
  font-family: Estedad;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/Estedad-FD/Estedad-FD-Thin.woff2") format("woff2");
}
@font-face {
  font-family: Estedad;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Estedad-FD/Estedad-FD-Regular.woff2") format("woff2");
}
@font-face {
  font-family: Estedad;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Estedad-FD/Estedad-FD-Medium.woff2") format("woff2");
}
@font-face {
  font-family: Estedad;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/Estedad-FD/Estedad-FD-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: Estedad;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../fonts/Estedad-FD/Estedad-FD-Bold.woff2") format("woff2");
}
@font-face {
  font-family: Estedad;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/Estedad-FD/Estedad-FD-ExtraBold.woff2") format("woff2");
}
@font-face {
  font-family: Estedad;
  font-style: normal;
  font-weight: 930;
  font-display: swap;
  src: url("../fonts/Estedad-FD/Estedad-FD-Black.woff2") format("woff2");
}

/* FiraCode */
@font-face {
  font-family: FiraCode;
  src: url("../fonts/Fira-Code/FiraCode-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: FiraCode;
  src: url("../fonts/Fira-Code/FiraCode-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: FiraCode;
  src: url("../fonts/Fira-Code/FiraCode-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: FiraCode;
  src: url("../fonts/Fira-Code/FiraCode-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: FiraCode;
  src: url("../fonts/Fira-Code/FiraCode-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
